import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import {
  Alert,
  Card,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Collapse,
  Modal,
  Spinner,
  Tooltip,
  UncontrolledTooltip,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import AsyncSelect from 'react-select/async-creatable';
import Select from 'react-select/creatable';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import * as Sentry from '@sentry/browser';
import NumberFormat from 'react-number-format';
import draftToHtml from 'draftjs-to-html';
import { default as CsvDropzone } from '../../components/dropzone';

import Button from '../../components/overrides/button';
import RowMoreOptions from '../../components/table/row-more-options';

import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import Icon from '../../components/icon';
import './style.scss';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Constants } from '../../constants';
import ModalDialog from '../../components/ModalDialog';
import { getAllProducts, postProduct, putProduct } from '../../redux/actions/shop.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import Pagination from '../../components/ui/base/pagination';
import TouchSpin from '../../components/touch-spin';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import Hashids from 'hashids';
import Toast from 'cogo-toast';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Cigar from '../../models/Cigar';
import Placeholder from '../../config/placeholder.config';
import withStyles from '@material-ui/styles/withStyles';
import Badge from '@material-ui/core/Badge';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const vitolaOptions = [{
  label: 'Churchill',
  value: 'churchill',
}, {
  label: 'Toro',
  value: 'toro',
}, {
  label: 'Torpedo',
  value: 'torpedo',
}, {
  label: 'Perfecto',
  value: 'perfecto',
}, {
  label: 'Cigarillo',
  value: 'cigarillo',
}];

const defaults = {
  editorState: EditorState.createEmpty(),
  selectedId: null,
  selectedUrl: null,
  selectedImageUrl: null,
  selectedProduct: {},
  imagesToAttach: [],
  imagesToRemove: [],
  selectedCategories: null,
  selectedPackageType: null,
  selectedPackageQuantity: 1,
  selectedAvailability: 'Out Of Stock',
  selectedImagesToAttach: [],
  selectedImagesToRemove: [],
  productImportType: 'single_import',
  uploading: false,
  selectedRecords: null,
  selectedRecordCount: 0,
  selectedInventoryFile: null,
  selectedCigarSuggestion: 0,
};

const defaultCategories = [
  // "All", // Always added to this one server-side, don't need to pick it
  'Cigars',
  'Samplers',
  'Bundles',
  'Cigar Aficionado Top 25', // These two will dynamically add it to the current year's category
  'IPCPR/PCA New Releases',
];

const defaultPackageType = [
  'Single',
  'Pack',
  'Box',
  'Bundle',
  'Jar',
  'Tin',
  'Sampler',
  'Subscription',
  'Accessory',
];

const extraDetails = [
  'Pyramid',
  'Short Robusto',
  'Robusto',
  'Cigarillos',
  'Short Torpedo',
  'Torpedo',
  'Short Churchill',
  'Churchill',
  'Lancero',
  'Panatela',
  'Toro',
  'Double Corona',
  'Corona Gordo',
  'Corona Gorda',
  'Gordo',
  'Double Toro',
  'Gran Robusto',
  'Double Robusto',
  'Salomon',
  'Presidente',
  'Prominente',
  'Belicoso',
  'Double Perfecto',
  'Petit Corona',
  'La Punta Perfecto',
  'Perfecto',
  'Corona',
  'Wedge',
  'Rothschild',
  'Lonsdale',
  'Trabuco',
  'Tronco',
  'Tin',
  'Jar',
  'Box Pressed',
  'Box-Pressed',
  'Boxpressed',
  'Fresh Pack',
  'Tubo',
  'Tubos',
  'Coffin',
  'La Fuerza',
  '1400cc',
  'No. 10',
  'No. 20',
  'No. 30',
  'No. 40',
  'No. 50',
  'No. 60',
  'No. 70',
  'No. 80',
  'No. 90',
  'No. 1',
  'No. 2',
  'No. 3',
  'No. 4',
  'No. 5',
  'No. 6',
  'No. 7',
  'No. 8',
  'No. 9',
  'No.1',
  'No.2',
  'No.3',
  'No.4',
  'No.5',
  'No.6',
  'No.7',
  'No.8',
  'No.9',
  'No1',
  'No2',
  'No3',
  'No4',
  'No5',
  'No6',
  'No7',
  'No8',
  'No9',
  '5 pack',
  '5 Pack',
  '5pk',
  '5 pk',
  '5 Pk',
];

function removeStringsFromArray(array, query) {
  // create a regular expression from the array of strings to match them globally in the query string
  const regex = new RegExp(array.join('|'), 'g');

  // replace all instances of the strings with an empty string and return the modified query string
  return query.replace(regex, '');
}

// TODO Maybe a list of common ones?
const defaultShops = [];

const StyledBadge = withStyles(() => ({
  badge: {
    marginLeft: -14,
    marginTop: 6,
    color: '#856404',
  },
}))(Badge);

const djsPreviewTemplate = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
    <div className="dz-details">
      {/* <div className="dz-filename"><span data-dz-name="true"></span></div> */}
      <img
        style={{
          maxWidth: 100,
          maxHeight: 100,
        }}
        data-dz-thumbnail="true"
      />
    </div>
    <div className="dz-progress">
      <div className="progress">
        <div className="progress-bar bg-success" role="progressbar" data-dz-uploadprogress>
          <span className="progress-text" />
        </div>
      </div>
    </div>
    {/* <div className="dz-success-mark"><span>✔</span></div> */}
    {/* <div className="dz-error-mark"><span>✘</span></div> */}
    {/* <div className="dz-error-message"><span data-dz-errormessage="true"></span></div> */}
  </div>,
);

class ShopTable extends React.Component {
  animating = false;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showProductModal: false,
      showImportModal: false,
      showImageUrlModal: false,
      showLinkCigarModal: false,
      showFilterDrawer: false,
      linkedCigars: [],
      vitolaOptions: [],
      showVitola: {},
      showAvailability: 'all',
      showProductType: 'all',
      showMissingLinkedCigars: false, // These 3 options are set to ONLY show the missing options. Setting to false shows all
      showMissingImages: false,
      showLowPrice: false,
      showQuanityMismatch: false,
      showMissingPrice: false,
      showPagePickerModal: false,
      selectedShop: null,
      selectedProductImages: [],
      cigarSuggestions: [],
      fetchedCigarSuggestions: false,
      submitting: false,
      message: {
        type: 'info',
        show: false,
        title: '',
        text: '',
      },
      ...defaults,
    };
    this.productImages = null;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  uploadMedia = (blob, extension, type) => {
    const formData = new FormData();

    // INFO Must be named 'media' to work with our server
    const productHash = hashids.encode(this.state.selectedProduct ? this.state.selectedProduct.id : new Date().getTime());
    formData.append('media', blob, `${productHash}-${type}.${extension}`);
    console.log(`${productHash}-${type}.${extension}`);

    console.log('Uploading to media server...');
    console.log('Uploading media to server...');

    const mediaInstance = axios.create();
    mediaInstance.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Accept: 'text/html',
    };
    this.setState({ uploadingMedia: true });
    mediaInstance.post(`${config.mediaEndPoint}/upload?type=products`, formData).then((res) => {
      console.log('Uploaded to media server!');
      console.log(JSON.stringify(res.data));
      // Sentry.setExtra('uploadMedia_res_data', JSON.stringify(res.data));
      // setUploadingMedia(false);
      this.setState((prevState) => ({
        uploadingMedia: false,
        [type]: res.data.media_url,
        selectedProductImages: [...prevState.selectedProductImages, {
          url: res.data.media_url,
        }],
      }));
      this.productImages.dropzone.removeAllFiles();
      const messageDiv = this.productImages.dropzone.element.closest('.dz-message');
      if (messageDiv) {
        messageDiv.style.display = 'block';
      } else {
        this.productImages.dropzone.element.children[0].style.display = 'block';
      }
    }).catch((err) => {
      this.setState({ uploadingMedia: false });
      // setUploadingMedia(false);
      // ErrorLogger.captureException(err);
      console.log(err);
    });
  };

  dropzoneEventHandlers(holder) {
    const self = this;

    return {
      addRemoveLinks: true,
      accept(file, done) {
        console.log('File:');
        console.log(file);
        console.log('uploaded');
        done();
      },
      init(dropzone) {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(self.state);
        const image = self.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // FIXME This doesn't always hide
          // Hide the message
          setTimeout(() => {
            console.log(dropzone.element);
            const messageDiv = dropzone.element.closest('.dz-message');
            if (messageDiv) {
              messageDiv.style.display = 'none';
            } else {
              dropzone.element.children[0].style.display = 'none';
            }
          }, 500);
        }
      },
      drop(event) {
        event.target.style.display = 'none';
        if (self[holder]) {
          const messageDiv = self[holder].dropzone.element.closest('.dz-message');
          if (messageDiv) {
            messageDiv.style.display = 'none';
          } else {
            self[holder].dropzone.element.children[0].style.display = 'none';
          }
          self.setState({
            activeDropzone: self[holder],
            activeDropzoneId: holder,
          });
        }
      },
      selectedfiles(files) {
        alert('Selected files');
      },
      addedfile(file) {
        if (file) {
          console.log(file);
          console.log('Loading file...');
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            console.log('Loaded file.');
            const dataUrl = event.target.result;
            console.log(dataUrl);
            // self.setState({
            //   selectedImageFile: dataUrl,
            //   showMediaCropper: true,
            // });
            // TODO Upload to server - we bypass the step of cropping here. After uploading, add the image to the list
            //  so it displays like all the rest when using import from URL and reset the dropzone so more can be added
            self.uploadMedia(file, fileReader.result.split(';')[0].split('/')[1], holder);
          };
          fileReader.readAsDataURL(file);
        }
      },
      uploadprogress(file, progress, bytesSent) {
        if (file.previewElement) {
          const progressElement = file.previewElement.querySelector('[data-dz-uploadprogress]');
          progressElement.style.width = `${progress}%`;
          progressElement.querySelector('.progress-text').textContent = `${progress}%`;
        }
      },
      // removedfile: self.handleRemoveUpload,
      // error: self.handleErrorUpload,
      success: (file, response, error) => {
        console.log(response);
      },
    };
  }

  imageFormatter = (cell, row, index) => {
    // console.log("Image:");
    // console.log(cell);
    if (cell) {
      if (cell.indexOf('http') === -1) {
        cell = cell.replace('//', '');
        cell = `https://${cell}`;
      }
      if (window.location.hostname === 'admin.sigaro.io' || window.location.hostname === 'admin.boxpressd.io') {
        cell = cell.replace('/products/', '/products/100x100/');
        console.log(cell);
      }
      return (
        <img
          style={{ maxWidth: 75 }}
          src={cell}
          onClick={() => {
            // console.log(cigar.images);
            // this.setState({photoImages: cigar.images, photoIndex: 0, openLightbox: true}) // TODO How to get correct image position? See above
          }}
        />
      );
    }
    return (<div />);
  };

  formatDecimal = (price) => {
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    if (typeof price === 'number') {
      price = price.toFixed(2);
    }
    return price;
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleProductChange = (key) => (event) => {
    const product = this.state.selectedProduct;
    product[key] = event.target.value;
    this.setState({ selectedProduct: product });
  };

  handleEditorStateChange = (editorState) => {
    console.debug('New editor state:');
    console.debug(editorState.getCurrentContent());
    console.debug(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    this.setState({
      editorState,
    });
  };

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  handleSelectChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleCheckboxChange = (key) => {
    console.log('Check change');
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  };

  handleRadioChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  handleLinkedCigarChange = (index, type) => {
    const { linkedCigars } = this.state;
    console.log(linkedCigars);
    const linkedCigar = linkedCigars[index] || {};
    return (value) => {
      linkedCigar[type] = value;
      linkedCigars[index] = linkedCigar;
      this.setState({ linkedCigars }, () => {
        console.log('State updated!');
        if (type === 'cigar') {
          console.log('Running this.loadVitolas(index)...');
          this.loadVitolas(index);
        }
      });
    };
  };

  handleLinkedCigarInputChange = (index, type) => {
    const { linkedCigars } = this.state;
    console.log(linkedCigars);
    const linkedCigar = linkedCigars[index] || {};
    return (event) => {
      linkedCigar[type] = event.target.value;
      linkedCigars[index] = linkedCigar;
      this.setState({ linkedCigars }, () => {
        console.log('State updated!');
        if (type === 'cigar') {
          console.log('Running this.loadVitolas(index)...');
          this.loadVitolas(index);
        }
      });
    };
  };

  getCigarSuggestions = (query) => {
    axios.get(`${Constants.clientPath}/cigars/search`, {
      params: {
        q: removeStringsFromArray(extraDetails, query).trim(),
      },
    }).then((response) => {
      console.log('Suggested cigars:');
      console.log(response.data);
      this.setState({
        fetchedCigarSuggestions: true,
        cigarSuggestions: response.data.map((cigar) => ({
          value: cigar.id,
          label: cigar.full_name,
          image_url: Cigar.getBandImage(cigar),
        })),
      });
    }).catch((err) => {
      console.log('Unable to get cigar suggestions');
      console.log(err);
      this.setState({
        fetchedCigarSuggestions: true,
      });
    });
  };

  getProductSuggestions = (query) => {
    const noAuth = axios.create();
    noAuth.defaults.headers.common = {};
    noAuth.get(`${Constants.shopPath}/products/search`, {
      params: {
        q: query,
        limit: 5,
      },
      headers: {},
    }).then((response) => {
      console.log('Suggested products:');
      console.log(response.data);
      this.setState({
        fetchedProductSuggestions: true,
        productSuggestions: response.data.filter((product) => product.contents && product.contents.length > 0),
      });
    }).catch((err) => {
      console.log('Unable to get product suggestions');
      console.log(err);
      this.setState({
        fetchedProductSuggestions: true,
      });
    });
  };

  keyForType = (type) => {
    switch (type) {
      case 'product':
        return 'showProductModal';
      case 'import':
        return 'showImportModal';
      case 'imageurl':
        return 'showImageUrlModal';
      case 'cigar':
        return 'showLinkCigarModal';
      case 'filter':
        return 'showFilterDrawer';
      case 'page':
        return 'showPagePickerModal';
      case 'datatable':
        return 'showImporterDatatable';
      default:
        return '';
    }
  };

  updateProductMedia = (productId) => {
    const {
      selectedImagesToAttach, // FIXME Technically can be a video down the road, too
      selectedImagesToRemove,
      tableChangeType,
      page,
      sizePerPage,
      searchText,
    } = this.state;

    if (selectedImagesToAttach.length > 0 || selectedImagesToRemove.length > 0) {
      axios.put(`${Constants.apiPath}/shop/products/${productId}/media`, {
        add: selectedImagesToAttach.map((url) => ({ url })),
        remove: selectedImagesToRemove,
      }).then((res) => {
        console.log('Added media successfully!');
        this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  onOpenModal = (type, row) => {
    if (row) {
      if (type === 'product') {
        this.getCigarSuggestions(row.name);
        this.getProductSuggestions(row.reference);

        console.log(JSON.stringify(row));

        const categories = [];
        let packagingType = null;
        let packagingQuantity = 1;

        let editorState = EditorState.createEmpty();
        if (row.description) {
          const contentBlock = htmlToDraft(row.description);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
          }
        }

        if (row.categories) {
          for (let i = 0; i < row.categories.length; i++) {
            const category = row.categories[i];
            categories.push({
              value: category,
              label: category,
            });
          }
        }

        if (row.packaging) {
          packagingType = {
            label: row.packaging.type,
            value: row.packaging.type,
          };
          packagingQuantity = row.packaging.quantity;
        }

        if (row.price && typeof row.price === 'number' && row.price > 0) {
          row.price = row.price.toFixed(2);
        }

        this.setState({
          editorState,
          selectedProduct: row,
          // productImages: row.image_url,
          selectedProductImages: row.images,
          selectedAvailability: row.availability ? {
            value: row.availability,
            label: row.availability,
          } : '',
          selectedCategories: categories,
          selectedPackageType: packagingType,
          selectedPackageQuantity: packagingQuantity,
          selectedVenue: row.venue ? {
            value: row.venue.id,
            label: this.formatVenueLabel(row.venue),
          } : null,
        });
      } else if (type === 'cigar') {
        if (!this.state.fetchedCigarSuggestions) {
          this.getCigarSuggestions(row.name);
        }
        console.log('Checking linked cigars...');
        console.log(row);
        if (row.contents && row.contents.length) {
          console.log('Found linked cigars! Building form...');
          console.log(row.contents);

          const linkedCigars = [];

          for (let i = 0; i < row.contents.length; i++) {
            const content = row.contents[i];
            const { cigar } = content;
            const vitola = content.vitola || {};
            linkedCigars.push({
              cigar: {
                label: cigar.full_name,
                value: cigar.id,
              },
              vitola: {
                label: vitola.formatted_name,
                value: vitola.id,
              },
              quantity: content.quantity,
            });
          }

          this.setState({ linkedCigars });
        }
      }
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    if (type === 'product') {
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    } else if (type === 'cigar') {
      this.setState({
        linkedCigars: [],
        [this.keyForType(type)]: false,
      });
    } else if (type === 'import' || type === 'imageurl') {
      this.setState({
        [this.keyForType(type)]: false,
        selectedUrl: null,
        selectedImageUrl: null,
      });
    } else if (type === 'datatable') {
      this.setState({
        // TODO Reset selected file etc
        ...defaults,
        [this.keyForType(type)]: false,
      });
    } else {
      this.setState({
        [this.keyForType(type)]: false,
      });
    }
  };

  onSaveModal = (type) => {
    if (type === 'product') {
      const product = this.state.selectedProduct;
      const {
        selectedVenue,
        selectedPackageType,
        selectedPackageQuantity,
        selectedAvailability,
        page,
        sizePerPage,
        searchText,
        tableChangeType,
        editorState,
      } = this.state;

      if (this.state.selectedCategories) {
        product.categories = [];
        for (let i = 0; i < this.state.selectedCategories.length; i++) {
          product.categories.push(this.state.selectedCategories[i].value);
        }
      }

      if (!product.venue_id && (!product.venue || !product.venue.id)) {
        if (selectedVenue) {
          product.venue_id = selectedVenue.value;
        } else {
          product.venue_id = 35712;
        }
      }

      if (selectedPackageType && selectedPackageType.value) {
        product.packaging = {
          type: selectedPackageType.value,
          quantity: selectedPackageType.value === 'Sampler' || selectedPackageType.value === 'Subscription' ? 0 : selectedPackageQuantity,
        };
      }

      // This is the difference between out of stock for good and allowing backorders
      product.availability = selectedAvailability ? selectedAvailability.value : 'Out Of Stock';
      // product.stock_count = 0;
      product.out_of_stock = selectedAvailability ? selectedAvailability.value !== 'In Stock' : true;

      console.debug('Draft to html:');
      console.debug(draftToHtml(editorState));
      if (editorState && draftToHtml(convertToRaw(editorState.getCurrentContent())).length > 0) {
        // TODO Sanitize
        product.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      }

      console.log('Submitting product...');
      console.log(product);

      this.setState({
        submitting: true,
      });

      const {
        postProduct,
        putProduct,
      } = this.props;

      if (product.id) {
        putProduct(product).then((updatedProduct) => {
          console.log(JSON.stringify(updatedProduct));
          this.setState({
            submitting: false,
            show: true,
            type: 'success',
            title: 'Success!',
            text: 'Product updated successfully',
          });

          this.updateProductMedia(product.id);
          this.onCloseModal('product');
          this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
        }).catch((e) => {
          console.log(e.message);
          this.setState({
            submitting: false,
            message: {
              type: 'error',
              show: true,
              title: 'Failed to update product',
              text: 'There was a problem updating this product. Please try again or contact an admin.',
            },
          });
        });
      } else {
        postProduct(product).then((updatedProduct) => {
          console.log(JSON.stringify(updatedProduct));
          this.setState({
            submitting: false,
            show: true,
            type: 'success',
            title: 'Success!',
            text: 'Product updated successfully',
          });

          this.updateProductMedia(updatedProduct.id);
          this.onCloseModal('product');
          this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
        }).catch((e) => {
          console.log(e.message);
          this.setState({
            submitting: false,
            message: {
              type: 'error',
              show: true,
              title: 'Failed to save product',
              text: 'There was a problem adding this product. Please try again or contact an admin.',
            },
          });
        });
      }
    } else if (type === 'import') {
      if (this.state.productImportType === 'single_import') {
        // FIXME Redux or helper class?
        // this.props.scrapeUrl(url)
        //     .then((product) => {
        //         this.onCloseModal('import');
        //         this.onOpenModal('product', product)
        //     })
        console.log(`Getting product details for ${this.state.selectedUrl}`);
        this.setState({
          submitting: true,
        });

        axios.get(`${Constants.apiPath}/scrape/product`, {
          params: {
            url: this.state.selectedUrl,
          },
        }).then((res) => {
          console.log(JSON.stringify(res.data));
          this.setState({
            submitting: false,
          });

          this.onCloseModal('import');
          this.onOpenModal('product', res.data);
        }).catch((e) => {
          console.log(e.message);
          this.setState({
            submitting: false,
            message: {
              type: 'error',
              show: true,
              title: 'Failed to scrape',
              text: 'There was a problem scraping this URL. Please try again or contact an admin.',
            },
          });
        });
      } else if (this.state.productImportType === 'bulk_import') {
        // TODO Test for a file and then process initial rows to display in new modal with shop select, similar to business panel
      } else {
        // TODO Do something? Make the user select one? Not sure when this would happen...
      }
    } else if (type === 'imageurl') {
      const urlParts = this.state.selectedImageUrl.split('?')[0].split('/');
      console.log({
        original_src: this.state.selectedImageUrl,
        filename: urlParts[urlParts.length - 1],
        prefix: 'products',
      });
      axios.post(`${config.mediaEndPoint}/import`, {}, {
        params: {
          original_src: this.state.selectedImageUrl,
          filename: urlParts[urlParts.length - 1],
          prefix: 'products', // This is the CDN prefix, e.g. 'products', 'cigars', etc
        },
      }).then((response) => {
        console.log(response.data);
        const media = response.data;
        const images = [...this.state.selectedProductImages];
        const imagesToAdd = [...this.state.selectedImagesToAttach];
        images.push({
          id: media.id,
          url: media.media_url,
        });
        imagesToAdd.push(media.media_url);
        this.setState({
          submitting: false,
          selectedProductImages: images,
          selectedImagesToAttach: imagesToAdd,
        });
        this.onCloseModal('imageurl');
      }).catch((err) => {
        console.log(err);
        this.setState({
          submitting: false,
        });
        this.onCloseModal('imageurl');
      });
    } else if (type === 'cigar') {
      if (this.state.selectedProduct && this.state.selectedProduct.id) {
        // We can save right away
        console.log('Linking cigars...');
        console.log(this.state.linkedCigars);
        console.log(`${Constants.apiPath}/shop/products/${this.state.selectedProduct.id}/cigars`);

        this.setState({
          submitting: true,
        });

        axios.post(`${Constants.apiPath}/shop/products/${this.state.selectedProduct.id}/cigars`, this.state.linkedCigars.map((linkedCigar) => ({
          product_id: parseInt(this.state.selectedProduct.id),
          cigar_id: parseInt(linkedCigar.cigar.value),
          vitola_id: linkedCigar.vitola ? parseInt(linkedCigar.vitola.value) : null,
          quantity: linkedCigar.quantity || 1,
        }))).then((res) => {
          console.log(JSON.stringify(res.data));

          this.setState({
            submitting: false,
          });

          this.onCloseModal('cigar');
          // FIXME Do we really want to close it?
          this.onCloseModal('product');
        }).catch((e) => {
          console.log(e.message);
          this.setState({
            submitting: false,
            message: {
              type: 'error',
              show: true,
              title: 'Failed to link',
              text: 'There was a problem linking these cigars. Please try again and ensure all information is included.',
            },
          });
        });
      } else {
        // TODO May not allow the option before the product is saved (definitely won't for client-facing version)
        //  Or we can link them after the product POST finishes
      }
    } else if (type === 'filter') {
      // TODO Update the conditions to filter products
      // this.setState({
      //     inStockOnly: ...
      // })
      this.onCloseModal('filter');
      this.handleTableChange(null, {
        page: 1,
        sizePerPage: 10,
      });
    } else if (type === 'datatable') {
      const {
        selectedInventoryFile,
        selectedImportVenue,
        importOptions,
      } = this.state;
      if (!selectedImportVenue || !selectedImportVenue.value) {
        alert('Please include the venue to link these products to');
        return;
      }
      if (!importOptions) {
        alert('Please select the import type');
        return;
      }
      this.setState({ uploading: true });
      // FIXME Pretty sure these need a JWT - our admin Firebase Auth Token won't work on this server...
      //  Should create a go-between endpoint on the shop server that creates the JWT and makes the call to the admin server
      //  This is really more of an admin action and we can maintain better control over it this way..
      axios.post(`${config.adminEndPoint}/shop/products/inventory/venues/${selectedImportVenue.value}/datasources/sign`, {
        filetype: selectedInventoryFile.type,
      }).then((result) => {
        console.debug('Signed response:');
        console.debug(JSON.stringify(result.data.signed_url));
        const uploadOptions = {
          method: 'PUT',
          body: selectedInventoryFile,
        };

        console.debug(result.data);

        console.debug('Direct upload in progress...');
        fetch(result.data.signed_url, uploadOptions).then(async () => {
          console.debug('S3 success');
          if (result.data.datasource_url) {
            let batchEndpoint = `${config.adminEndPoint}/shop/products/inventory/batch`;
            if (importOptions === 'update_images') {
              batchEndpoint = `${batchEndpoint}/images`;
            }
            console.debug('Kicking off step function...');
            await axios.post(batchEndpoint, {
              venue_id: selectedImportVenue.value,
              datasource_url: result.data.datasource_url,
              update_only: importOptions === 'update_only',
            }).then((results) => {
              // TODO Update local state with the new datasource? Or just show alert that it's importing and they will be notified when complete?
              this.setState({ uploading: false, showImporterModal: false });
              Toast.success('You will receive an email when complete', { heading: 'Product Sync Started', position: 'top-right' });
            }).catch((err) => {
              console.error(err);
              this.setState({ uploading: false });
              // TODO Alert the user with ModalDialog? Reuse it for all of these catches...
              alert('Something went wrong. Please contact our support team at support@boxpressd.com');
            });
          } else {
            console.error('No data URL...');
            this.setState({ uploading: false });
            // Alert admins - something went wrong
            Sentry.setExtra('s3_signed_url_response', { id: result.id, datasource_url: result.datasource_url });
            Sentry.captureException('Unable to upload media to S3');
            alert('Something went wrong. Please contact our support team at support@boxpressd.com');
          }
        }).catch((err) => {
          this.setState({ uploading: false });
          console.debug('S3 failure:');
          console.debug(err);
          alert('Something went wrong. Please contact our support team at support@boxpressd.com');
        });
      }).catch((err) => {
        this.setState({ uploading: false });
        console.error(err);
        alert('Something went wrong. Please contact our support team at support@boxpressd.com');
      });
    }
  };

  syncImages = (product) => {
    // TODO Show loading indicator
    axios.put(`${Constants.apiPath}/shop/products/${product.id}/images/migrate`).then((res) => {
      console.log(res);
      this.setState({
        message: {
          type: 'success',
          show: true,
          title: 'Success!',
          text: 'The images have been successfully updated.',
        },
      });
    }).catch((err) => {
      console.log(err);
      this.setState({
        message: {
          type: 'info',
          show: true,
          title: 'No Images',
          text: 'Unable to update images for this product. If the product has images, please check the logs for an error.',
        },
      });
    });
  };

  syncPackaging = (product) => {
    // TODO Show loading indicator
    axios.put(`${Constants.apiPath}/shop/products/packaging/missing?reference=${product.reference}`)
      .then((res) => {
        console.log(res);
        this.setState({
          message: {
            type: 'success',
            show: true,
            title: 'Success!',
            text: 'The packaging has been successfully updated.',
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          message: {
            type: 'warning',
            show: true,
            title: 'Not Updated',
            text: 'Unable to update packaging for this product. Please check the logs for an error.',
          },
        });
      });
  };

  checkPrices = (product) => {
    // TODO Show loading indicator
    axios.get(`${Constants.apiPath}/shop/products/${product.id}/pricecheck?reference=${product.reference}`)
      .then((res) => {
        console.log(res);
        this.setState({
          message: {
            type: 'success',
            show: true,
            title: 'Success!',
            text: 'The prices have been successfully updated.',
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          message: {
            type: 'warning',
            show: true,
            title: 'Not Updated',
            text: 'Unable to update prices for this product. Please check the logs for an error.',
          },
        });
      });
  };

  syncProductAttribute = (product) => {
    if (product.prestashop_product_attribute_id) {
      console.log('This product doesn\'t need updated');
      this.setState({
        message: {
          type: 'info',
          show: true,
          title: 'Update to date',
          text: 'This product already has a product attribute linked.',
        },
      });
    } else if (product.packaging && product.packaging.type !== 'Sampler') {
      console.log('Getting info from server...');
      // TODO Show loading indicator
      axios.put(`${Constants.apiPath}/shop/products/attributes/migrate?reference=${product.reference}`)
        .then((res) => {
          console.log(res);
          this.setState({
            message: {
              type: 'success',
              show: true,
              title: 'Success!',
              text: 'The product attribute has been successfully updated. You can now view this product on Prestashop.',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            message: {
              type: 'warning',
              show: true,
              title: 'Not Updated',
              text: 'This product does not have an associated product attribute. If you know there is one in Prestashop, please check the logs.',
            },
          });
        });
    } else {
      console.log('Can\'t update samplers...');
      this.setState({
        message: {
          type: 'info',
          show: true,
          title: 'Unable to Update',
          text: 'Samplers don\'t have product attributes and cannot be linked to one.',
        },
      });
    }
  };

  venueFormatter = (cell, row) => {
    if (row.venue) {
      return row.venue.name;
    }
    return 'N/A';
  };

  packagingFormatter = (cell, row) => {
    if (cell) {
      // FIXME Move this to the server?
      let formattedPackaging = `${cell.type} of ${cell.quantity}`;
      if (cell.type === 'Sampler' || cell.type === 'Single' || cell.type === 'Accessory' || cell.type === 'Subscription') {
        formattedPackaging = cell.type;
      }
      return (
        <>{formattedPackaging}</>
      );
    }
    return null;
  };

  vitolaFormatter = (contents, row) => {
    if (contents && contents.length && row.packaging && row.packaging.type !== 'Sampler' && row.packaging.type !== 'Subscription') {
      console.log(contents[0]);
      if (contents[0].vitola) {
        return (
          <>{contents[0].vitola.formatted_name}</>
        );
      }
    } else if (contents && contents.length && row.packaging && row.packaging.type === 'Sampler') {
      return (
        <>Varies</>
      );
    }
    return null;
  };

  priceFormatter = (cell, row) => {
    if (cell) {
      let price = cell;
      if (typeof price === 'number' || (typeof price === 'string' && price.indexOf('$') === -1)) {
        // FIXME This should be fixed on the server, but since the dev db and prod db aren't configured the same, we need this. Once configured correctly, remove this
        price = `$${parseFloat(cell).toFixed(2)}`;
      }
      return (
        <label>{price}</label>
      );
    }
    return null;
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  statusFormatter = (cell, row) => {
    console.log(cell);
    if (cell && cell === 'ok') {
      return (
        <div style={{ marginLeft: 15 }}>
          <i className="fa fa-circle text-success" />
        </div>
      );
    } if (cell && cell === 'warn') {
      return (
        <div style={{ marginLeft: 15 }}>
          <i className="fa fa-exclamation-circle text-warning" id={`warning-${row.id}`} />
          <UncontrolledTooltip placement="top" target={`warning-${row.id}`}>
            <ul>
              {row.status_message.map((message) => (
                <li>
-
                  {message}
                </li>
              ))}
            </ul>
          </UncontrolledTooltip>
        </div>
      );
    } if (cell && cell === 'unlinked') {
      return (
        <div style={{ marginLeft: 15 }}>
          <i className="fa fa-unlink text-danger" id={`unlinked-${row.id}`} />
          <UncontrolledTooltip placement="top" target={`unlinked-${row.id}`}>
            <ul>
              {row.status_message.map((message) => (
                <li>
-
                  {message}
                </li>
              ))}
            </ul>
          </UncontrolledTooltip>
        </div>
      );
    } if (cell && cell === 'unlinked-warn') {
      return (
        <div style={{ marginLeft: 15 }}>
          <i className="fa fa-unlink text-warning" id={`unlinked-${row.id}`} />
          <UncontrolledTooltip placement="top" target={`unlinked-${row.id}`}>
            <ul>
              {row.status_message.map((message) => (
                <li>
-
                  {message}
                </li>
              ))}
            </ul>
          </UncontrolledTooltip>
        </div>
      );
    } if (cell && cell === 'missing-attribute') {
      return (
        <div style={{ marginLeft: 15 }}>
          <i className="fa fa-cart-plus text-danger" id={`missing-attribute-${row.id}`} />
          <UncontrolledTooltip placement="top" target={`missing-attribute-${row.id}`}>
            <ul>
              {
                row.status_message.map((message) => (
                  <li>
-
                    {message}
                  </li>
                ))
              }
            </ul>
          </UncontrolledTooltip>
        </div>
      );
    }
    return (
      <div style={{ marginLeft: 15 }}>
        <i className="fa fa-exclamation-triangle text-danger" id={`error-${row.id}`} />
        <UncontrolledTooltip placement="top" target={`error-${row.id}`}>
          <ul>
            {row.status_message.map((message) => (
              <li>
-
                {message}
              </li>
            ))}
          </ul>
        </UncontrolledTooltip>
      </div>
    );
  };

  actionDropdown = (index, product) => (
    <RowMoreOptions
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => {
          this.onOpenModal('product', product);
          this.onOpenModal('cigar', product);
        },
        title: 'Link Cigars',
      }, {
        type: 'MenuItem',
        onClick: () => {
          this.syncImages(product);
        },
        title: 'Sync Images',
      }, {
        type: 'MenuItem',
        onClick: () => {
          this.syncPackaging(product);
        },
        title: 'Sync Packaging Info',
      }, {
        type: 'MenuItem',
        onClick: () => {
          axios.post(`${Constants.apiPath}/actions/products/${product.id}/search/index`).then(() => {
            Toast.success('Successfully re-indexed products', { heading: product.name, position: 'top-right' });
          }).catch((error) => {
            console.error(error);
            Toast.error('Unable to re-index product', { heading: product.name, position: 'top-right' });
          });
        },
        title: 'Re-index in Search',
      }, {
        type: 'MenuItem',
        onClick: () => {
          this.checkPrices(product);
        },
        title: 'Price Check',
        // }, {
        //   type: 'MenuItem',
        //   onClick: () => {
        //     this.syncProductAttribute(product);
        //   },
        //   title: 'Sync Product Attribute'
      }, {
        type: 'MenuItem',
        onClick: () => {
          alert('Not yet implemented! Currently, products are disabled by setting the quantity to 0 in the database.');
        },
        style: { color: '#ff4700' },
        title: 'Delete',
      }]}
      onShow={() => {
        // Hide the row buttons
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      }}
    />
  );

  editBtnFormatter = (cell, row, index) => {
    console.log(`Index rendered: ${index}`);
    return (
      <div style={{ width: 68, float: 'right' }}>
        <div id={`dropdown-actions-${index}`} className="d-none">
          <Icon
            name="edit2"
            className="ml-1 mr-3"
            style={{
              cursor: 'pointer',
              display: 'inline',
              height: 18,
              width: 18,
            }}
            onClick={() => this.onOpenModal('product', row)}
          />
          {this.actionDropdown(index, row)}
        </div>
      </div>
    );
  };

  handlePageChange = (e, index) => {
    this.setState({
      page: index,
    });
    this.props.getAllProducts(index + 1, this.state.sizePerPage, null, {
      in_stock_only: this.state.showAvailability === 'in-stock',
      out_of_stock_only: this.state.showAvailability === 'out-of-stock',
      no_issues_only: this.state.showAvailability === 'no-issues',
      shop_id: this.state.selectedShop ? this.state.selectedShop.value : null,
      product_type: this.state.showProductType !== 'all' ? this.state.showProductType : null,
      missing_linked_cigars: this.state.showMissingLinkedCigars,
      missing_images: this.state.showMissingImages,
      missing_price: this.state.showMissingPrice,
      low_price: this.state.showLowPrice,
    });
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
    console.log('Updating table...');
    this.props.getAllProducts(page, sizePerPage, searchText, {
      in_stock_only: this.state.showAvailability === 'in-stock',
      out_of_stock_only: this.state.showAvailability === 'out-of-stock',
      no_issues_only: this.state.showAvailability === 'no-issues',
      shop_id: this.state.selectedShop ? this.state.selectedShop.value : null,
      product_type: this.state.showProductType !== 'all' ? this.state.showProductType : null,
      missing_linked_cigars: this.state.showMissingLinkedCigars,
      missing_images: this.state.showMissingImages,
      missing_price: this.state.showMissingPrice,
      low_price: this.state.showLowPrice,
      quantity_mismatch: this.state.showQuanityMismatch,
      sort: sortField,
      direction: sortOrder,
    });

    this.setState({
      tableChangeType: type,
      page,
      sizePerPage,
      searchText,
    });
  };

  closeAlert = () => {
    this.setState({
      message: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  resetFilters = () => {
    // TODO Set them back to defaults and reload
    alert('Reset coming soon! Please refresh your browser to reset.');
  };

  renderFilterOptions = () => (
    <SwipeableDrawer
      anchor="right"
      open={this.state.showFilterDrawer}
      // onClick={() => this.setState({ showFilterDrawer: false })}
      // onKeyDown={() => this.setState({ showFilterDrawer: false })}
      onClose={() => this.setState({ showFilterDrawer: false })}
    >
      {/* TODO Add app bar on mobile with back arrow */}
      <div
        style={{
          width: isMobile ? 'calc(100vw - 40px)' : 300,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 30,
        }}
      >
        <div style={{ height: 20 }} />
        <FormLabel component="legend">By Shop</FormLabel>
        <div className="select-wrapper" style={{ maxWidth: 'calc(100% - 40px)' }}>
          <AsyncSelect
            value={this.state.selectedShop || { label: 'All Shops', value: -1 }}
            onChange={this.handleSelectChange('selectedShop')}
            loadOptions={this.loadShops}
            defaultOptions={[{ label: 'All Shops', value: -1 }, ...defaultShops]}
            isOptionSelected={(option) => this.state.selectedShop === option}
          />
        </div>
        <FormLabel component="legend" style={{ marginTop: 12 }}>By Product Type</FormLabel>
        <RadioGroup
          row
          aria-label="product type"
          name="product_type"
          value={this.state.showProductType}
          onChange={this.handleRadioChange('showProductType')}
        >
          <FormControlLabel
            value="all"
            control={<Radio color="primary" />}
            label="All"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Sampler"
            control={<Radio color="primary" />}
            label="Samplers"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Box"
            control={<Radio color="primary" />}
            label="Boxes"
            labelPlacement="end"
          />
          {/* FIXME Should we keep them separate as the db has them? Or combine like here? */}
          <FormControlLabel
            value="Pack"
            control={<Radio color="primary" />}
            label="Pack / Bundle"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Jar"
            control={<Radio color="primary" />}
            label="Jars"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Tin"
            control={<Radio color="primary" />}
            label="Tins"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Single"
            control={<Radio color="primary" />}
            label="Singles"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Subscription"
            control={<Radio color="primary" />}
            label="Subscriptions"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Accessory"
            control={<Radio color="primary" />}
            label="Accessories"
            labelPlacement="end"
          />
        </RadioGroup>
        <div>
          <FormControl component="fieldset" style={{ marginTop: 12 }}>
            <FormLabel component="legend">By Availability</FormLabel>
            <RadioGroup
              row
              aria-label="availability"
              name="availability"
              value={this.state.showAvailability}
              onChange={this.handleRadioChange('showAvailability')}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label="All"
                labelPlacement="end"
              />
              <FormControlLabel
                value="in-stock"
                control={<Radio color="primary" />}
                label="In Stock"
                labelPlacement="end"
              />
              <FormControlLabel
                value="out-of-stock"
                control={<Radio color="primary" />}
                label="Out Of Stock"
                labelPlacement="end"
              />
              <FormControlLabel
                value="no-issues"
                control={<Radio color="primary" />}
                label="No Issues"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <FormLabel component="legend" style={{ marginTop: 12 }}>By Vitola</FormLabel>
        {/* TODO A multi select field instead? */}
        {vitolaOptions.map((vitola) => (
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.showVitola[vitola.value]}
                  onChange={this.handleCheckboxChange('showVitola', vitola.value)}
                  value="primary"
                  inputProps={{ 'aria-label': `vitola ${vitola.value}` }}
                />
              )}
              label={vitola.label}
            />
          </div>
        ))}
        <FormLabel component="legend" style={{ marginTop: 12 }}>By Issue</FormLabel>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showMissingLinkedCigars}
                onChange={this.handleCheckboxChange('showMissingLinkedCigars')}
                value="primary"
                inputProps={{ 'aria-label': 'Missing linked cigars' }}
              />
            )}
            label="Missing linked cigars"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showMissingImages}
                onChange={this.handleCheckboxChange('showMissingImages')}
                value="primary"
                inputProps={{ 'aria-label': 'Missing images' }}
              />
            )}
            label="Missing images"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showMissingPrice}
                onChange={this.handleCheckboxChange('showMissingPrice')}
                value="primary"
                inputProps={{ 'aria-label': 'Missing / invalid price' }}
              />
            )}
            label="Missing / invalid price"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showLowPrice}
                onChange={this.handleCheckboxChange('showLowPrice')}
                value="primary"
                inputProps={{ 'aria-label': 'No profit margin' }}
              />
            )}
            label="No profit margin"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showQuanityMismatch}
                onChange={this.handleCheckboxChange('showQuanityMismatch')}
                value="primary"
                inputProps={{ 'aria-label': 'Quantity / stock Mismatch' }}
              />
            )}
            label="Quantity / stock Mismatch"
          />
        </div>

        <div
          style={{
            display: 'flex',
            position: 'fixed',
            bottom: 0,
            backgroundColor: 'white',
            width: 300,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 'auto',
          }}
        >
          <Button
            onClick={() => {
              this.setState({
                showFilterDrawer: false,
              }, this.resetFilters);
            }}
            style={{
              display: 'block',
              flex: 1,
              marginRight: 10,
              textAlign: 'center',
            }}
          >
            {'Reset'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              display: 'block',
              flex: 1,
              textAlign: 'center',
            }}
            onClick={() => this.onSaveModal('filter')}
          >
            {'Apply'}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );

  render() {
    const { sizePerPage, page } = this.state;
    const { data, totalSize, loadingProducts } = this.props.ShopManager;

    const columns = [{
      dataField: 'image_url',
      text: '',
      formatter: this.imageFormatter,
      sort: false,
      editable: false,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
      // filter: textFilter()
    }, {
      dataField: 'venue',
      text: 'Sold By',
      // sort: true,
      formatter: this.venueFormatter,
    }, {
      dataField: 'packaging',
      text: 'Packaging',
      formatter: this.packagingFormatter,
    }, {
      dataField: 'contents',
      text: 'Vitola',
      formatter: this.vitolaFormatter,
    }, {
      dataField: 'reference',
      text: 'Reference',
      sort: true,
      // filter: textFilter()
    }, {
      dataField: 'sale_price',
      text: 'Price',
      formatter: this.priceFormatter,
      sort: true,
      // }, {
      //     dataField: 'quantity',
      //     text: 'Quantity',
      //     sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      formatter: this.statusFormatter,
      sort: true, // TODO Implement this on the server?
    }, { // TODO Use similar handling as cigar manager to show dynamic icons
      dataField: 'edit',
      text: '',
      formatter: this.editBtnFormatter,
    }];

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
    return (
      <div>
        <SweetAlert
          show={this.state.message.show}
          type={this.state.message.type}
          title={this.state.message.title}
          onConfirm={this.closeAlert}
        >
          {this.state.message.text}
        </SweetAlert>

        <Breadcrumb title="Product Manager" label="Product Manager" parent="Shop" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                          <DebounceSearchBar
                            {...toolkitprops.searchProps}
                            placeholder="Search products..."
                            onChange={() => this.setState({ page: 1 })}
                          />
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary"
                            onClick={(event) => {
                              this.setState({ anchorEl: event.currentTarget, showMoreMenu: true });
                            }}
                            style={{
                              padding: '6px 14px',
                              float: 'right',
                              marginLeft: '10px',
                            }}
                          >
                            <MoreVertIcon />
                          </button>
                          <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                              'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={this.state.anchorEl}
                            open={this.state.showMoreMenu}
                            onClose={() => this.setState({ anchorEl: null, showMoreMenu: false })}
                          >
                            <MenuItem
                              disableRipple
                              onClick={() => {
                                this.setState({ anchorEl: null, showMoreMenu: false });
                                axios.post(`${Constants.apiPath}/actions/products/search/index`).then(() => {
                                  Toast.success('Successfully re-indexed products', { position: 'top-right' });
                                }).catch((error) => {
                                  console.error(error);
                                  Toast.error('Unable to re-index products', { position: 'top-right' });
                                });
                              }}
                            >
                              Re-index All Products
                            </MenuItem>
                          </Menu>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.onOpenModal('product')}
                            style={{ float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-plus" />) : 'Add Product'}
                          </button>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => this.onOpenModal('import')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (
                              <i className="icon icon-import" />) : 'Import Product'}
                          </button>
                          <button
                            className="btn btn-outline-primary mr-2"
                            onClick={() => this.onOpenModal('filter')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-filter" />) : 'Filter'}
                          </button>

                          <PaginationProvider
                            pagination={paginationFactory({
                              pageButtonRenderer,
                              page,
                              sizePerPage,
                              // withFirstAndLast: false,
                              custom: true,
                              totalSize,
                              showTotal: true,
                            })}
                          >
                            {
                              ({
                                paginationProps,
                                paginationTableProps,
                              }) => (
                                <div>
                                  <div style={{ marginLeft: 10, float: 'left' }}>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    data={data}
                                    columns={columns}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    wrapperClasses="table-responsive"
                                    {...paginationTableProps}
                                    cellEdit={cellEditFactory(cellEditProps)}
                                    // filter={ filterFactory() }
                                    onTableChange={this.handleTableChange}
                                    noDataIndication={() => (!loadingProducts
                                      ? <NoDataIndicator message="No products found matching filters or search query." />
                                      : <TableLoader />)}
                                    {...toolkitprops.baseProps}
                                  />
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                  <div style={{ width: 200, display: 'inline-block', float: 'right' }}>
                                    <Pagination
                                      hideNavigation
                                      page={page}
                                      sizePerPage={sizePerPage}
                                      totalSize={totalSize}
                                      handlePageChange={this.handlePageChange}
                                      onShowPagePicker={() => this.onOpenModal('page')}
                                    />
                                  </div>
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              )
                            }
                          </PaginationProvider>

                          {this.pagePickerModal()}
                          {this.productModal()}
                          {this.importModal()}
                          {this.renderImporterDatatableModal()}
                          {this.imageFromUrlModal()}
                          {this.linkCigarModal()}
                          {this.renderFilterOptions()}
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    this.setState((prevState) => ({ selectedCigarSuggestion: prevState.selectedCigarSuggestion === this.state.cigarSuggestions.length - 1 ? 0 : prevState.selectedCigarSuggestion + 1 }));
  }

  previous() {
    if (this.animating) return;
    this.setState((prevState) => ({ selectedCigarSuggestion: prevState.selectedCigarSuggestion === 0 ? this.state.cigarSuggestions.length - 1 : prevState.selectedCigarSuggestion - 1 }));
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ selectedCigarSuggestion: newIndex });
  }

  productModal = () => {
    const {
      showProductModal,
      selectedProduct,
      selectedVenue,
      selectedProductImages,
      selectedPackageType,
      selectedPackageQuantity,
      selectedAvailability,
      editorState,
      submitting,
    } = this.state;

    return (
      <Modal
        isOpen={showProductModal}
        onClosed={() => this.onCloseModal('product')}
        toggle={() => this.onCloseModal('product')}
        className="modal-dialog-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">{selectedProduct.name ? 'Edit Product' : 'Add Product'}</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <Card style={{ padding: 10 }}>
                <div className="row">
                  <div className="col-sm-12">
                    <span style={{ fontWeight: 700 }}>Title</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        value={selectedProduct.name}
                        onChange={this.handleProductChange('name')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span style={{ fontWeight: 700 }}>Description</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      {/* <textarea className='form-control' value={this.state.selectedProduct.description} onChange={this.handleProductChange('description')} /> */}
                      <Editor
                        editorState={editorState}
                        wrapperClassName="editor-wrapper"
                        editorClassName="form-control"
                        onEditorStateChange={this.handleEditorStateChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span style={{ fontWeight: 700 }}>Purchase URL</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        value={selectedProduct.buy_url}
                        onChange={this.handleProductChange('buy_url')}
                      />
                    </div>
                  </div>
                </div>
              </Card>
              <Card style={{ padding: 10 }}>
                <div className="row" style={{ display: 'flex' }}>
                  <div className="col-md-12">
                    <div>
                      <span style={{ fontWeight: 700 }}>Images</span>
                      <span
                        style={{
                          color: 'rgb(211, 169, 102)',
                          cursor: 'pointer',
                          float: 'right',
                        }}
                        onClick={() => this.onOpenModal('imageurl')}
                      >
                        {'Add image from URL'}
                      </span>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                      {selectedProductImages && selectedProductImages.map((image) => (
                        <div
                          className="col-md-3"
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            cursor: 'pointer',
                          }}
                        >
                          <div className="image-overlay">
                            <Icon
                              name="trash"
                              className="delete-image-icon"
                              onClick={() => {
                                console.log('Clicked delete icon');
                                console.log(image);
                                ModalDialog.show({
                                  title: 'Delete image?',
                                  message: 'Are you sure you want to delete this image?',
                                  type: 'error',
                                  buttons: [{
                                    label: 'Cancel',
                                    onClick: () => {
                                      ModalDialog.close();
                                    },
                                  }, {
                                    label: 'Delete',
                                    style: { color: 'rgb(239, 81, 100)' },
                                    onClick: () => {
                                      console.log('Adding image to remove...');
                                      console.log(image);
                                      const images = this.state.selectedProductImages;
                                      for (let i = 0; i < images.length; i++) {
                                        if (images[i].id === image.id) {
                                          images.splice(i, 1);
                                          break;
                                        }
                                      }

                                      const imagesToRemove = [...this.state.selectedImagesToRemove];
                                      imagesToRemove.push(image);
                                      this.setState({
                                        selectedProductImages: images,
                                        selectedImagesToRemove: imagesToRemove,
                                      }, () => {
                                        console.log(this.state);
                                      });
                                      ModalDialog.close();
                                    },
                                  }],
                                });
                              }}
                            />
                            <Icon
                              name="maximize2"
                              className="expand-icon"
                              onClick={() => {
                                console.log('Clicked expand icon');
                                alert('Coming soon!');
                              }}
                            />
                          </div>
                          <img
                            src={image.url}
                            style={{
                              width: '100%',
                              borderRadius: 5,
                              border: '1px solid #dfdfdf',
                            }}
                          />
                        </div>
                      ))}
                      <div className="product-images">
                        <Dropzone
                          id="productImages"
                          ref={(ref) => this.productImages = ref}
                          config={{
                            postUrl: 'no-url',
                            iconFiletypes: ['.jpg', '.png'],
                          }}
                          djsConfig={{
                            dictDefaultMessage: 'Add images or drop here to upload',
                            previewTemplate: djsPreviewTemplate,
                          }}
                          eventHandlers={this.dropzoneEventHandlers('productImages')}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="dropzone">
                              <div style={{ height: '100%' }} {...getRootProps()}>
                                <input {...getInputProps()} />
                                {/* <p>Drop images here</p> */}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card style={{ padding: 10 }}>
                <div className="row">
                  <div className="col-sm-12">
                    <span style={{ fontWeight: 700 }}>Reference (SKU)</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        value={selectedProduct.reference}
                        onChange={this.handleProductChange('reference')}
                      />
                    </div>
                    <p style={{ fontSize: 12 }}>The SKU or other reference identifying the product</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span style={{ fontWeight: 700 }}>Barcode (UPC)</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        value={selectedProduct.ean13}
                        onChange={this.handleProductChange('ean13')}
                      />
                    </div>
                    <p style={{ fontSize: 12 }}>The EAN13 barcode for the product</p>
                  </div>
                </div>
              </Card>
              <Card style={{ padding: 10 }}>
                <div className="row">
                  <div className="col-sm-4">
                    <span style={{ fontWeight: 700 }}>MSRP</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <NumberFormat
                          className="form-control"
                          thousandsGroupStyle="thousand"
                          value={selectedProduct.msrp}
                          // prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          type="text"
                          thousandSeparator
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale
                          onChange={this.handleProductChange('msrp')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <span style={{ fontWeight: 700 }}>Price</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <NumberFormat
                          className="form-control"
                          thousandsGroupStyle="thousand"
                          value={selectedProduct.sale_price}
                          // prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          type="text"
                          thousandSeparator
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale
                          onChange={this.handleProductChange('sale_price')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <span style={{ fontWeight: 700 }}>Cost</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <NumberFormat
                          className="form-control"
                          thousandsGroupStyle="thousand"
                          value={selectedProduct.cost_price}
                          // prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          type="text"
                          thousandSeparator
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale
                          onChange={this.handleProductChange('cost_price')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{ padding: 10 }}>
                <span style={{ fontWeight: 700 }}>Shop</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncSelect
                    value={selectedVenue}
                    onChange={this.handleSelectChange('selectedVenue')}
                    loadOptions={this.loadShops}
                    isOptionSelected={(option) => this.state.selectedVenue === option}
                  />
                </div>
              </Card>
              <Card style={{ padding: 10 }}>
                <div className="row">
                  <div
                    className={selectedPackageType && (selectedPackageType.value === 'Pack' || selectedPackageType.value === 'Box' || selectedPackageType.value === 'Bundle' || selectedPackageType.value === 'Jar' || selectedPackageType.value === 'Tin') ? 'col-md-7' : 'col-md-12'}
                  >
                    <span style={{ fontWeight: 700 }}>Product Type</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <AsyncSelect
                        value={selectedPackageType}
                        onChange={this.handleSelectChange('selectedPackageType')}
                        defaultOptions={defaultPackageType.map((value) => ({
                          value,
                          label: value,
                        }))}
                        isOptionSelected={(option) => selectedPackageType === option}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-5"
                    style={{ display: selectedPackageType && (selectedPackageType.value === 'Pack' || selectedPackageType.value === 'Box' || selectedPackageType.value === 'Bundle' || selectedPackageType.value === 'Jar' || selectedPackageType.value === 'Tin') ? 'inline' : 'none' }}
                  >
                    <span style={{ fontWeight: 700 }}>Quantity</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        value={selectedPackageQuantity}
                        onChange={this.handleInputChange('selectedPackageQuantity')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span style={{ fontWeight: 700 }}>Tags (coming soon)</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <AsyncSelect
                        value={this.state.selectedCategories}
                        placeholder="Add or select..."
                        onChange={this.handleSelectChange('selectedCategories')}
                        defaultOptions={defaultCategories.map((value) => ({
                          value,
                          label: value,
                        }))}
                        isMulti
                        isOptionSelected={(option) => this.state.selectedCategories === option}
                      />
                    </div>
                  </div>
                </div>
              </Card>
              {(selectedPackageType?.value !== 'Accessory') && (
                <Card style={{ padding: 10 }}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <span style={{ fontWeight: 700 }}>Linked Cigars</span>
                        <span
                          style={{
                            color: 'rgb(211, 169, 102)',
                            cursor: 'pointer',
                            float: 'right',
                          }}
                          onClick={() => this.onOpenModal('cigar', selectedProduct)}
                        >
                          {'Manage'}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        {selectedProduct.contents && selectedProduct.contents.length > 0 && selectedProduct.contents.map((content) => {
                          // FIXME Why is this sometimes undefined?
                          if (content.cigar) {
                            return (
                              <div>
                                {content.cigar.full_name}
                                {' '}
                                -
                                {' '}
                                {content.vitola && content.vitola.formatted_name}
                                {' '}
                                (x
                                {content.quantity}
                                )
                              </div>
                            );
                          }
                          return <div>There is an issue with this linked cigar</div>;
                        })}
                        {(!selectedProduct.contents || selectedProduct.contents.length === 0) && (
                          <>
                            {
                              (!this.state.cigarSuggestions || this.state.cigarSuggestions.length === 0)
                              && (!this.state.productSuggestions || this.state.productSuggestions.length === 0)
                              && <div>No linked cigars</div>
                            }
                            {/* FIXME Need a better way to determine showing one of these vs the other, or simply merge them */}
                            {this.state.productSuggestions && this.state.productSuggestions.length > 0 && (
                              <>
                                <Carousel
                                  interval={false}
                                  activeIndex={this.state.selectedCigarSuggestion || 0}
                                  next={this.next}
                                  previous={this.previous}
                                >
                                  <CarouselIndicators
                                    items={this.state.cigarSuggestions}
                                    activeIndex={this.state.selectedCigarSuggestion || 0}
                                    onClickHandler={this.goToIndex}
                                  />
                                  {this.state.productSuggestions.map((item) => (
                                    <CarouselItem
                                      key={item.id}
                                      onExiting={this.onExiting}
                                      onExited={this.onExited}
                                    >
                                      <Alert color="warning" className="suggestions">
                                        <div>Suggested cigars</div>
                                        {item.contents.map((content) => (
                                          <ListItem>
                                            <ListItemAvatar>
                                              <StyledBadge color="secondary" badgeContent={content.quantity}>
                                                <Avatar src={Cigar.getBandImage(content.cigar)} className="avatar-contained">
                                                  <img src={Placeholder.band} style={{ height: 40, width: 40 }} />
                                                </Avatar>
                                              </StyledBadge>
                                            </ListItemAvatar>
                                            <ListItemText
                                              primary={content.cigar.full_name}
                                              secondary={content.vitola ? content.vitola.formatted_name : ''}
                                            />
                                          </ListItem>
                                        ))}
                                        <Button
                                          onClick={() => {
                                            // FIXME I think if we have qty, cigar, and vitola for all, then just link,
                                            //  them without the extra step - this should work for now tho
                                            const linkedCigars = [];
                                            item.contents.forEach((content) => {
                                              linkedCigars.push({
                                                cigar: {
                                                  label: content.cigar.full_name,
                                                  value: content.cigar.id,
                                                },
                                                vitola: {
                                                  label: content.vitola.formatted_name,
                                                  value: content.vitola.id,
                                                },
                                                quantity: content.quantity,
                                              });
                                            });
                                            this.setState({
                                              linkedCigars,
                                            }, () => {
                                              this.onOpenModal('cigar', selectedProduct);
                                            });
                                          }}
                                          style={{ float: 'right' }}
                                        >
                                          {'Accept'}
                                        </Button>
                                        <div className="clearfix" />
                                      </Alert>
                                    </CarouselItem>
                                  ))}
                                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                </Carousel>
                              </>
                            )}
                            {this.state.cigarSuggestions && this.state.cigarSuggestions.length > 0 && (
                              <Carousel
                                interval={false}
                                activeIndex={this.state.selectedCigarSuggestion || 0}
                                next={this.next}
                                previous={this.previous}
                              >
                                <CarouselIndicators
                                  items={this.state.cigarSuggestions}
                                  activeIndex={this.state.selectedCigarSuggestion || 0}
                                  onClickHandler={this.goToIndex}
                                />
                                {this.state.cigarSuggestions.map((item) => (
                                  <CarouselItem
                                    key={item.id}
                                    onExiting={this.onExiting}
                                    onExited={this.onExited}
                                  >
                                    <Alert color="warning" className="suggestions">
                                      <div>Suggested cigars</div>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <Avatar src={item.image_url} className="avatar-contained">
                                            <img src={Placeholder.band} style={{ height: 40, width: 40 }} />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.label} />
                                      </ListItem>
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            linkedCigars: [{
                                              cigar: item,
                                            }],
                                          }, () => {
                                            this.loadVitolas(0);
                                            this.onOpenModal('cigar', selectedProduct);
                                          });
                                        }}
                                        style={{ float: 'right' }}
                                      >
                                        {'Accept'}
                                      </Button>
                                      <div className="clearfix" />
                                    </Alert>
                                  </CarouselItem>
                                ))}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                              </Carousel>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              )}
              {/* TODO Add this feature back in - right now, no sales through us yet */}
              {/* <Card style={{ padding: 10 }}> */}
              {/*  <div className="row"> */}
              {/*    <div className="col-sm-12"> */}
              {/*      <span style={{ fontWeight: 700 }}>Shipping</span> */}
              {/*      <div */}
              {/*        style={{ */}
              {/*          marginTop: 10, */}
              {/*          marginBottom: 10, */}
              {/*        }} */}
              {/*      > */}
              {/*        /!* TODO Allow the prices to be set? Shipping services or available rates (set some place else like Shopify)? *!/ */}
              {/*        Coming soon - for now, these are standard prices. (M&D charges $10 */}
              {/*        or 3% for standard Ground, whichever is higher, so almost all will only be $10 - when I had */}
              {/*        Prestashop running, I discounted it to $8.99 to compete with Cigars International and the other */}
              {/*        big retailers. Full rates available */}
              {/*        {' '} */}
              {/*        <a */}
              {/*          href="https://www.meierdutch.com/help/shipping-policies/16/" */}
              {/*          target="_blank" */}
              {/*        > */}
              {/*        here */}
              {/*        </a> */}
              {/*        .) In the future, I would like */}
              {/*        to be able to offer free or 99 cent shipping, like Vivino (for 1M sales, they bring in an extra million just by charging the extra 99c for shipping). */}
              {/*      </div> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </Card> */}
              <Alert
                color="light"
                style={{
                  boxShadow: '0 1px 11px 0 rgba(0, 0, 0, 0.1)',
                  border: '1px solid #dfdfdf',
                }}
              >
                <span>These fields are automatically updated daily. Overriding will only last beyond the next sync date for discontinuted items (records missing from the CSV file).</span>
                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-md-7">
                    <span style={{ fontWeight: 700 }}>Availability</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Select
                        value={selectedAvailability}
                        onChange={(selection) => {
                          this.setState({
                            selectedAvailability: selection,
                          });
                        }}
                        options={[{
                          value: 'In Stock',
                          label: 'In Stock',
                        }, {
                          value: 'Out Of Stock',
                          label: 'Out Of Stock',
                        }, {
                          value: 'Backordered',
                          label: 'Backordered',
                        }]}
                        getOptionLabel={(option) => `${option.label}`}
                        getOptionValue={(option) => `${option}`}
                        isOptionSelected={(option) => selectedAvailability && (selectedAvailability.value === option.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <span style={{ fontWeight: 700 }}>Stock Count</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div className="input-group">
                        <input
                          className="form-control"
                          value={selectedProduct.stock_count}
                          onChange={this.handleProductChange('stock_count')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Alert>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('product')}>Cancel</Button>
          <Button
            disabled={submitting}
            onClick={() => this.onSaveModal('product')}
            variant="contained"
            color="secondary"
          >
            {submitting && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
            {submitting ? 'Saving' : 'Save'}
          </Button>
        </div>
      </Modal>
    );
  };

  importModal = () => (
    <Modal
      isOpen={this.state.showImportModal}
      onClosed={() => this.onCloseModal('import')}
      toggle={() => this.onCloseModal('import')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Import Products</h5>
      </div>
      <div className="modal-body">
        <RadioGroup
          row
          aria-label="product import type"
          name="product_import_type"
          value={this.state.productImportType}
          onChange={this.handleRadioChange('productImportType')}
        >
          <FormControlLabel
            value="single_import"
            control={<Radio color="primary" />}
            label="Single product from URL"
            labelPlacement="end"
          />
          <FormControlLabel
            value="bulk_import"
            control={<Radio color="primary" />}
            label="Bulk import from file"
            labelPlacement="end"
          />
        </RadioGroup>
        <Collapse isOpen={this.state.productImportType === 'single_import'}>
          {'URL'}
          {' '}
          <i className="icon-info-alt" id="urlTooltip" />
          <Tooltip
            placement="top"
            isOpen={this.state.showUrlTooltip}
            target="urlTooltip"
            toggle={this.toggleTooltip('showUrlTooltip')}
          >
              Importable URLs include:
            {' '}
            <br />
            <br />
            <ul>
              <li>https://www.meierdutch.com/item/[*]</li>
              {/* <li>https://www.cigarsinternational.com/p/[*]</li> */}
            </ul>
          </Tooltip>
          <input
            className="form-control"
            type="text"
            name="import-url"
            value={this.state.selectedUrl}
            onChange={this.handleInputChange('selectedUrl')}
          />
        </Collapse>
        <Collapse isOpen={this.state.productImportType === 'bulk_import'}>
          <div>File Upload</div>
          <CsvDropzone
            id="dropzone"
            config={{
              postUrl: 'no-url',
              iconFiletypes: ['.csv'],
            }}
            djsConfig={{
              dictDefaultMessage: 'Add csv file or drop here to upload',
              previewTemplate: djsPreviewTemplate,
            }}
            onChange={(files) => {
              // eslint-disable-next-line no-console
              console.log(files);
              this.setState({
                selectedInventoryFile: files[0],
              }, () => {
                this.onCloseModal('import');
                this.onOpenModal('datatable');
              });
            }}
            getJson={(json) => {
              const selectedRecords = json.slice(0, 10);
              console.debug(selectedRecords);
              this.setState({
                selectedRecords,
                selectedRecordCount: json.length,
              });
            }}
            style={{ minHeight: 'auto' }}
          />
        </Collapse>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('import')}>Cancel</Button>
        <Button
          disabled={this.state.submitting}
          onClick={() => this.onSaveModal('import')}
          variant="contained"
          color="secondary"
        >
          {this.state.submitting
            && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
          {this.state.submitting ? 'Importing' : 'Import'}
        </Button>
      </div>
    </Modal>
  );

  renderImporterDatatableModal = () => {
    const {
      selectedRecords,
      selectedRecordCount,
      showImporterDatatable,
      uploading,
    } = this.state;
    if (selectedRecords && selectedRecordCount) {
      console.log(`Showing the first ${selectedRecords.length < 10 ? selectedRecords.length : 10} out of ${selectedRecordCount} records`);
    }
    return (
      <Modal
        isOpen={showImporterDatatable}
        toggle={() => this.onCloseModal('datatable')}
        onClosed={() => this.onCloseModal('datatable')}
      >
        <div className="modal-header">
          <h5 className="modal-title h2">Confirm</h5>
          <Button className="close" color="" onClick={() => this.onCloseModal('datatable')}>
            <Icon name="x" />
          </Button>
        </div>
        <div className="modal-body">
          {selectedRecords && selectedRecordCount && (
            <div>
              <div>
                <RadioGroup aria-label="import options" name="import_options" value={this.state.importOptions} onChange={(e) => this.setState({ importOptions: e.target.value })}>
                  <FormControlLabel
                    value="update_only"
                    control={<Radio />}
                    label={(
                      <div>
                        <h6>Update existing records only</h6>
                        <p>Use this option if you want to update prices and stock count for products you have already listed in your shop.</p>
                      </div>
                    )}
                  />
                  <FormControlLabel
                    value="update_images"
                    control={<Radio />}
                    label={(
                      <div>
                        <h6>Update missing images only</h6>
                        <p>Use this option if you want to bulk import images for products. No other data will be updated.</p>
                      </div>
                    )}
                  />
                  <FormControlLabel
                    value="import_all"
                    control={<Radio />}
                    label={(
                      <div>
                        <h6>Import missing products and update existing</h6>
                        <p>Use this option if you want to import any products from the CSV not already listed in your shop in addition to updating prices and stock count.</p>
                      </div>
                    )}
                  />
                </RadioGroup>
              </div>
              <div>Venue:</div>
              <AsyncSelect
                value={this.state.selectedImportVenue}
                onChange={this.handleSelectChange('selectedImportVenue')}
                isOptionSelected={(option) => this.state.selectedImportVenue === option}
                loadOptions={this.loadVenues.bind(this)}
              />
              <div style={{ marginTop: 26 }}>
                {`Showing the first ${selectedRecords.length < 10 ? selectedRecords.length : 10} out of ${selectedRecordCount} records`}
              </div>
              {/* TODO We should validate here to ensure required fields are present with the correct names */}
              <DataTable
                noHeader
                pagination={false}
                columns={Object.keys(selectedRecords[0]).map((name) => ({
                  name: name.replace('_', ' ').replace('_', ' ')
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' '),
                  selector: name,
                }))}
                data={selectedRecords}
                selectableRows={false}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('datatable')}>Cancel</Button>
          <Button
            disabled={uploading}
            onClick={() => this.onSaveModal('datatable')}
            variant="contained"
            color="secondary"
          >
            {uploading
            && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
            {uploading ? 'Importing...' : 'Import'}
          </Button>
        </div>
      </Modal>
    );
  };

  imageFromUrlModal = () => (
    <Modal
      isOpen={this.state.showImageUrlModal}
      onClosed={() => this.onCloseModal('imageurl')}
      toggle={() => this.onCloseModal('imageurl')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Add Image From URL</h5>
      </div>
      <div className="modal-body">
          Paste Image URL
        <input
          className="form-control"
          type="text"
          name="image-url"
          placeholder="https://"
          value={this.state.selectedImageUrl}
          onChange={this.handleInputChange('selectedImageUrl')}
        />
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('imageurl')}>Cancel</Button>
        <Button
          disabled={this.state.submitting}
          onClick={() => this.onSaveModal('imageurl')}
          variant="contained"
          color="secondary"
        >
          {this.state.submitting
            && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
          {this.state.submitting ? 'Adding image' : 'Add image'}
        </Button>
      </div>
    </Modal>
  );

  renderLinkedCigars = () => {
    if (this.state.selectedPackageType) {
      if (this.state.selectedPackageType.value === 'Sampler' || this.state.selectedPackageType.value === 'Subscription') {
        const linkedCigars = [...this.state.linkedCigars];
        linkedCigars.push({}); // Adds an extra empty object to the end
        return (
          <div className="linked-cigar-block">
            {linkedCigars.map((cigar, index) => this.renderLinkedCigarsRow(this.state.selectedPackageType.value, index))}
            {/* <button type="button" className="btn btn-outline-primary" onClick={() => {}}>Add Another</button> */}
          </div>
        );
      }
      return (
        <div>
          {this.renderLinkedCigarsRow(this.state.selectedPackageType.value, 0)}
        </div>
      );
    }
    return (
      <div>
        <span>Please set the product package type before linking cigars</span>
        <AsyncSelect
          value={this.state.selectedPackageType}
          onChange={this.handleSelectChange('selectedPackageType')}
          defaultOptions={defaultPackageType.map((value) => ({
            value,
            label: value,
          }))}
          isOptionSelected={(option) => this.state.selectedPackageType === option}
        />
      </div>
    );
  };

  // http://www.shahqaan.com/blog/react-select/
  loadCigars = (searchTerm, callback) => {
    // FIXME These should probably use the database rather than the client code that pulls from Elasticsearch
    console.log(`${Constants.clientPath}/cigars/search?q=${searchTerm}`);
    return axios.get(`${Constants.clientPath}/cigars/search?q=${searchTerm}`)
      .then((res) => {
        console.log(res.data);
        callback(res.data.map((cigar) => ({
          value: cigar.id,
          label: cigar.full_name,
        })));
      }).catch((err) => {
        console.log(err);
      });
  };

  loadVenues = (searchTerm, callback) => {
    console.log(`${Constants.clientPath}/venues/search?q=${searchTerm}`);
    return axios.get(`${Constants.clientPath}/venues/search?q=${searchTerm}`).then((res) => {
      console.log(res.data);
      callback(res.data.map((venue) => ({
        value: venue.id,
        label: venue.name, // TODO Include address and/or website?
      })));
    }).catch((err) => {
      console.log(err);
    });
  };

  loadVitolas = (index) => {
    console.log('Running loadVitolas()...');
    const linkDetails = this.state.linkedCigars[index] || {};
    console.log('Got linked cigar detail:');
    console.log(linkDetails);
    if (linkDetails.cigar && linkDetails.cigar.value) {
      console.log(`${Constants.clientPath}/cigars/${linkDetails.cigar.value}/vitolas`);
      axios.get(`${Constants.clientPath}/cigars/${linkDetails.cigar.value}/vitolas`)
        .then((res) => {
          console.log(res.data);
          const { vitolaOptions } = this.state;
          vitolaOptions[index] = res.data.map((vitola) => ({
            value: vitola.id,
            label: vitola.formatted_name,
          }));
          this.setState({
            vitolaOptions,
          });
        }).catch((err) => {
          console.log(err);
        });
    }
  };

  renderLinkedCigarsRow = (type, index) => {
    console.debug('Linked cigars row:');
    console.debug(this.state.linkedCigars);
    console.debug(`Index: ${index}`);
    const linkDetails = this.state.linkedCigars[index] || {};
    const quantity = linkDetails.quantity || 1;
    if (type === 'Sampler') {
      return (
        <div
          className="row"
          style={{
            marginLeft: linkDetails.cigar ? 10 : 0,
            marginRight: linkDetails.cigar ? 10 : 0,
          }}
        >
          <div className={linkDetails.cigar ? 'col-md-5' : 'col-md-12'} style={{ padding: 0 }}>
            <span>{linkDetails.cigar ? 'Cigar' : 'Search Cigar To Link'}</span>
            <AsyncSelect
              value={linkDetails.cigar}
              onChange={this.handleLinkedCigarChange(index, 'cigar')}
              loadOptions={this.loadCigars.bind(this)}
              defaultOptions={this.state.cigarSuggestions}
              isOptionSelected={(option) => linkDetails.cigar === option}
            />
          </div>
          {linkDetails.cigar && (
            <div className="col-md-4">
              <span>Vitola</span>
              <Select
                value={linkDetails.vitola}
                onChange={this.handleLinkedCigarChange(index, 'vitola')}
                options={this.state.vitolaOptions[index] || []}
                isOptionSelected={(option) => linkDetails.vitola === option}
              />
            </div>
          )}
          {linkDetails.cigar && (
            <div className="col-md-2">
              <span>Quantity</span>
              <input
                className="form-control"
                value={quantity}
                type="number"
                onChange={this.handleLinkedCigarInputChange(index, 'quantity')}
              />
            </div>
          )}
          {linkDetails.cigar && (
            <div
              style={{
                lineHeight: '60px',
                marginTop: 8,
              }}
            >
              <Icon
                name="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // TODO Remove the linkDetails from this.state.linkedCigars (or a clone to only apply on Save)
                  const { linkedCigars } = this.state;
                  linkedCigars.splice(index, 1);
                  this.setState({ linkedCigars });
                }}
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-6">
          <span>Cigar</span>
          <AsyncSelect
            value={linkDetails.cigar}
            onChange={this.handleLinkedCigarChange(index, 'cigar')}
            loadOptions={this.loadCigars.bind(this)}
            defaultOptions={this.state.cigarSuggestions}
            isOptionSelected={(option) => linkDetails.cigar === option}
          />
        </div>
        <div className="col-md-6">
          <span>Vitola</span>
          <Select
            value={linkDetails.vitola}
            onChange={this.handleLinkedCigarChange(index, 'vitola')}
            options={this.state.vitolaOptions[index] || []}
            isOptionSelected={(option) => linkDetails.vitola === option}
          />
        </div>
      </div>
    );
  };

  linkCigarModal = () => (
    <Modal
      isOpen={this.state.showLinkCigarModal}
      onClosed={() => this.onCloseModal('cigar')}
      toggle={() => this.onCloseModal('cigar')}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {`Link cigars${this.state.selectedProduct.name && ` to ${this.state.selectedProduct.name}`}`}
        </h5>
      </div>
      <div className="modal-body">
        {this.renderLinkedCigars()}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('cigar')}>Cancel</Button>
        <Button
          disabled={this.state.submitting}
          onClick={() => this.onSaveModal('cigar')}
          variant="contained"
          color="secondary"
        >
          {this.state.submitting
            && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
          {this.state.submitting ? 'Saving' : 'Save'}
        </Button>
      </div>
    </Modal>
  );

  formatVenueLabel = (venue) => {
    console.log(venue);
    let label = venue.name;
    if (venue.location.formatted_address) {
      label += ` - ${venue.location.formatted_address}`;
    } else if (venue.website) {
      label += ` - ${venue.website}`;
    }
    return label;
  };

  loadShops = (searchTerm, callback) => {
    console.log(`${Constants.clientPath}/venues/search?q=${searchTerm}`);
    return axios.get(`${Constants.clientPath}/venues/search?q=${searchTerm}`)
      .then((res) => {
        console.log(res.data);
        callback(res.data.map((venue) => ({
          value: venue.id,
          label: this.formatVenueLabel(venue),
        })));
      }).catch((err) => {
        console.log(err);
      });
  };

  // FIXME Make this a re-usable component
  pagePickerModal = () => (
    <Modal
      isOpen={this.state.showPagePickerModal}
      onClosed={() => this.onCloseModal('page')}
      toggle={() => this.onCloseModal('page')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Select Page</h5>
      </div>
      <div className="modal-body">
        <TouchSpin
          max={Math.ceil(this.props.ShopManager.totalSize / this.state.sizePerPage)}
          min={1}
          step={1}
          value={this.state.number || (!this.state.edited && this.state.page)}
          onChange={(value) => {
            this.setState({ edited: true, number: value });
          }}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('page')}>
          {'Cancel'}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            this.handlePageChange(null, this.state.number);
            this.onCloseModal('page');
          }}
        >
          {'Go'}
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ ShopManager }) => ({ ShopManager });

export default connect(
  mapStateToProps, {
    getAllProducts,
    postProduct,
    putProduct,
  },
)(ShopTable);
